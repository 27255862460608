<template>
    <div class="profile-details profile-details-dropdown">
        <ul>
            <li
                v-for="(triple, tripleIndex) in mainPredicates"
                :key="tripleIndex"
            >
                <div
                    v-if="triple.predicate == 'Latitude and Longitude'"
                    style="height: 100px; width: 100%"
                >
                    <l-map
                        ref="dropdown-map"
                        id="dropdown-map"
                        v-model:zoom="zoom"
                        :center="triple.object"
                        :options="{
                            dragging: false,
                            zoomControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: false,
                            boxZoom: false,
                            attributionControl: false,
                        }"
                    >
                        <l-tile-layer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                            layer-type="base"
                            attribution="Tiles &copy; Esri"
                        ></l-tile-layer>
                        <l-marker :lat-lng="triple.object"></l-marker>
                    </l-map>
                </div>
                <div v-if="triple.predicate == 'has note'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.summary") }}</span>
                    <p>
                        {{ triple.object }}
                    </p>
                </div>
                <div v-if="triple.predicate == 'Dates'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.dates") }}</span>
                    <p>
                        {{ $t("EntityDetailsDropdown.birth") }} -
                        {{ triple["Date of Birth"] ?? "Unknown" }},
                        {{ triple["Place of Birth"] ?? "Unknown" }}
                        <br />
                        {{ $t("EntityDetailsDropdown.death") }} -
                        {{ triple["Date of Death"] ?? "Unknown" }},
                        {{ triple["Place of Death"] ?? "Unknown" }}
                    </p>
                </div>
                <div v-if="triple.predicate == 'Created'" class="pro-details-box">
                    <span class="property-label">{{ $t("EntityDetailsDropdown.created") }}</span>
                    <p>
                        {{ triple["Date of Creation"] ?? "Unknown" }},
                        {{ triple["Place of Creation"] ?? "Unknown" }}
                    </p>
                </div>
                <div 
                    v-if="
                        triple.predicate == 'Pursuits' ||
                        triple.predicate == 'Attributes' ||
                        triple.predicate == 'Creator of' ||
                        triple.predicate == 'Birthplace of' ||
                        triple.predicate == 'Deathplace of' ||
                        triple.predicate == 'Categories' ||
                        triple.predicate == 'Members' ||
                        triple.predicate == 'Published' ||
                        triple.predicate == 'Creators'
                    "
                    class="pro-details-box">
                    <span class="property-label">{{ triple.predicate }}</span>
                    <p class="truncate-text">
                        {{ triple.object }}
                    </p>
                </div>
            </li>
            <li class="pro-details-box">
                <span class="property-label">{{ $t("EntityDetailsDropdown.dataset") }}</span>
                <p class="truncate-text" :title="data.mainEntityInfo.graph">
                    {{ data.mainEntityInfo.graph }}
                </p>
            </li>
            <li class="pro-details-box">
                <span class="property-label">{{ $t("EntityDetailsDropdown.uri") }}</span>
                <a
                    class="url-link truncate-text"
                    :href="
                        'https://rs-review.lincsproject.ca/resource/?uri=' +
                        data.mainEntityInfo.resource
                    "
                    target="_blank"
                    :title="data.mainEntityInfo.resource"
                    >{{ data.mainEntityInfo.resource
                    }}<img
                        class="img-fluid external-link"
                        src="@/assets/external-link.svg"
                        alt="External link"
                /></a>
            </li>
            <li>
                <button
                    class="pro-details-box view-more-button"
                    tabindex="0"
                    role="link"
                    v-on:click="$emit('update')"
                >
                    <span class="url-link view-more-text">{{
                        $t("EntityDetailsDropdown.view_more")
                    }}</span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
@Options({
    props: {
        data: Object,
    },
    emits: ["update"],
    components: {
        LMap,
        LMarker,
        LTileLayer,
    },
})
export default class EntityDetailsDropdown extends Vue {
    data!: any;
    zoom = 8;

    // Gets a list of unique predicates for a given entity
    get mainPredicates() {
        function onlyUnique(value: any, index: any, array: any) {
            const map = array.map((pred: any) => pred.predicate);
            return map.indexOf(value.predicate) === index;
        }

        const allPreds = this.data.mainEntityInfo.outgoing == null 
            ? [] 
            : this.data.mainEntityInfo.outgoing.map(
                (pred: any) => ({
                    predicate: pred.predicateLabel ?? pred.predicate,
                    object: pred.object,
                }),
            );

        for (let i = 0; i < allPreds.length; i++) {
            if (
                allPreds[i].predicate == "Date of Birth" ||
                allPreds[i].predicate == "Date of Death"
            ) {
                const newPred = {
                    predicate: "Dates",
                };
                for (let j = 0; j < allPreds.length; j++) {
                    if (
                        allPreds[j].predicate == "Date of Birth" ||
                        allPreds[j].predicate == "Date of Death" ||
                        allPreds[j].predicate == "Place of Birth" ||
                        allPreds[j].predicate == "Place of Death"
                    ) {
                        (newPred as any)[allPreds[j].predicate] =
                            allPreds[j].object;

                        j--;
                        allPreds.splice(j, 1);
                        if (j < i) i--;
                    }
                }
                allPreds.push(newPred);
            } else if (
                allPreds[i].predicate == "Date of Creation" ||
                allPreds[i].predicate == "Place of Creation"
            ) {
                const newPred = {
                    predicate: "Created",
                };
                for (let j = 0; j < allPreds.length; j++) {
                    if (
                        allPreds[j].predicate == "Date of Creation" ||
                        allPreds[j].predicate == "Place of Creation"
                    ) {
                        (newPred as any)[allPreds[j].predicate] =
                            allPreds[j].object;

                        j--;
                        allPreds.splice(j, 1);
                        if (j < i) i--;
                    }
                }
                allPreds.push(newPred);
            } else if (
                allPreds[i].predicate == "Pursuits" ||
                allPreds[i].predicate == "Attributes" ||
                allPreds[i].predicate == "Creator of" ||
                allPreds[i].predicate == "Birthplace of" ||
                allPreds[i].predicate == "Deathplace of" ||
                allPreds[i].predicate == "Categories" ||
                allPreds[i].predicate == "Members" ||
                allPreds[i].predicate == "Published" ||
                allPreds[i].predicate == "Creators"
            ) {
                let str = "";
                for (const obj of allPreds[i].object) {
                    str += obj.label + "; ";
                }

                str = str.substring(0, str.length - 2);
                allPreds[i].object = str;
            }
        }

        return allPreds.filter(onlyUnique);
    }
}
</script>
<style>
ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.profile-details-dropdown {
    background-color: #083943;
}

.url-link {
    color: white;
    display: block;
    text-decoration: none;
    font-size: 14px;
}

.url-link .external-link {
    width: 14px;
    margin-left: 5px;
}

.profile-section.light-bg .url-link {
    color: #107386;
}

.property-label {
    color: #BAD8DE;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.high-tone span {
    cursor: pointer;
}

.view-more-button {
    width: 95%;
    margin: 5px 0px 12px 8px;
    padding: 8px 16px !important;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.view-more-button:hover {
    background-color: #E2F0F3;
}

.view-more-text {
    color: #083943;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
</style>
