<template>
    <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap");
#app {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    margin: 0px;
    width: 350px;
    overflow: hidden;
}
</style>
